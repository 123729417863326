import React, { createContext, useContext, useState } from 'react'
import BaixeGratisPopup from './_BaixeGratisPopup'

export type BaixeGratisPopupContextValue = {
  isOpen: boolean;
  hasCtaBeenClicked: boolean;
  openPopup: () => void;
  closePopup: () => void;
  setPopupHasError: (state: boolean) => void;
}

type BaixeGratisPopupProviderProps = {
  children: React.ReactNode;
}

export const BaixeGratisPopupContext = createContext<BaixeGratisPopupContextValue | undefined>(undefined)

export const BaixeGratisPopupProvider = ({ children }: BaixeGratisPopupProviderProps) => {
  const [ isOpen, setIsOpen ] = useState(false)
  const [ hasError, setHasError ] = useState(false)
  const [ hasCtaBeenClicked, setHasBeenClicked ] = useState(false)

  const openPopup = () => {
    setIsOpen(true)
  }

  const closePopup = () => {
    setIsOpen(false)
    setHasBeenClicked(false)
  }
  const internalClick = () => {
    setHasBeenClicked(true)
  }

  const contextValue: BaixeGratisPopupContextValue = {
    isOpen,
    openPopup,
    closePopup,
    setPopupHasError: setHasError,
    hasCtaBeenClicked,
  }

  return (
    <BaixeGratisPopupContext.Provider value={contextValue}>
      <BaixeGratisPopup
        hasError={hasError}
        onCtaClick={internalClick}
        closePopup={closePopup}
        isOpen={isOpen} openPopup={openPopup}
      />
      {children}
    </BaixeGratisPopupContext.Provider>
  )
}

export const useBaixeGratisPopup = () => {
  const context = useContext(BaixeGratisPopupContext)
  if (!context) {
    throw new Error('useBaixeGratisPopup must be used within a BaixeGratisPopupProvider')
  }
  return context
}

const withBaixeGratisPopup = (WrappedComponent: React.FC<any>) => {
  return (props: any) => {
    return (
      <BaixeGratisPopupProvider>
        <WrappedComponent {...props} />
      </BaixeGratisPopupProvider>
    )
  }
}

export default withBaixeGratisPopup
