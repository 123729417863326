import { useStaticQuery, graphql } from 'gatsby'

const Query = () => {
  const query = useStaticQuery(graphql`
    query {
      popupImageHomeEquityBlack: imageSharp(fluid: {originalName: { regex: "/popup-image-home-equity/" }}) {
        fluid(maxWidth: 183, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      popupImageFinanciamentoImobiliario: imageSharp(fluid: {originalName: { regex: "/popup-image-financiamento-imobiliario/" }}) {
        fluid(maxWidth: 183, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      popupImagePortabilidade: imageSharp(fluid: {originalName: { regex: "/popup-image-portabilidade/" }}) {
        fluid(maxWidth: 183, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      popupImageHomeCredito: imageSharp(fluid: {originalName: { regex: "/popup-image-home-credito/" }}) {
        fluid(maxWidth: 183, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      popupImageConstrucasa: imageSharp(fluid: {originalName: { regex: "/popup-image-construcasa/" }}) {
        fluid(maxWidth: 183, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      emprestimoPublicoPopup: imageSharp(fluid: { originalName: { regex:"/popup-emprestimo-publico/" }}) {
        fluid(maxWidth: 183, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bannerPopupFinImobResidencial: imageSharp(fluid: { originalName: { regex:"/popup-image-financiamento-imobiliario-residencial/" }}) {
        fluid(maxWidth: 183, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      popupConcursoSuperaPj2: imageSharp(fluid: { originalName: { regex:"/popup-concurso-supera-pj-2/" }}) {
        fluid(maxWidth: 183, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      popupInvistaNoSeuFuturo: imageSharp(fluid: { originalName: { regex:"/popup-invista-no-seu-futuro/" }}) {
        fluid(maxWidth: 215, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return query
}

export default Query
