import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import { spacing12, spacing16, spacing24, spacing32, spacing8 } from 'src/styles/spacings'
import styled from 'styled-components'

export const TextContent = styled.div`
  text-align: center;
  margin-bottom: ${spacing16};

  @media ${device.tablet} {
    & {
      margin-bottom: 56px;
    }
  }
`

export const GridBox = styled.ul`
  list-style: none;
  display: grid;
  grid-auto-rows: minmax(32px, 1fr);
  grid-template-columns: 1fr;
  grid-row-gap: ${spacing16};
  margin-bottom: ${spacing24};

  .grid-item {
    display: grid;
    grid-template-columns: 24px 1fr;
    font-size: 14px;
    line-height: 16px;
    grid-column-gap: ${spacing12};
    color: ${grayscale[500]};
    align-content: center;
    p {
      margin: 0;
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }


  @media ${device.tablet} {
    & {
      grid-template-columns: repeat(4, 1fr);
      grid-gap: calc(${spacing32} + ${spacing8});
      margin-bottom: 56px;
    }

    .grid-item {
      grid-template-columns: 1fr;
      grid-row-gap: ${spacing16};
      grid-template-rows: 72px 1fr;
      justify-items: center;
      text-align: center;
      svg {
        width: 72px;
        height: 72px;
      }
    }
  }
`

export const CtaBox = styled.div`
  .button-link {
    text-decoration: none;
    height: fit-content;
  }

  @media ${device.tablet} {
    & {
      max-width: 307px;
      margin: 0 auto;
    }
  }

  @media ${device.desktopLG} {
    & {
      max-width: 408px;
    }
  }

  @media ${device.desktopXXL} {
    & {
      max-width: 480px;
    }
  }
`
