import React, { MouseEvent } from 'react'
import Img from 'gatsby-image'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import Close from 'inter-frontend-svgs/lib/v2/navigation/close'

import dataJson from './assets/data/data.json'

import usePageQuery from './pageQuery'

import { Container, CloseButton, DefaultPopUp } from './style'
import { DataProps, PopupProps } from './types'

const Popup = ({
  closeModal, setPopup, pageName, child, pj,
  theme,
}: PopupProps) => {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()
  const handleClick = (evt: MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    closeModal()
    setPopup && setPopup(true)
  }

  if (child) {
    return (
      <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
        <Container className='container' pj={pj} themeBackgroundColor={theme?.backgroundColor ?? "#fff"}>
          <CloseButton className='border-0 bg-transparent pt-4' onClick={handleClick}>
            <Close width='30' height='30' color={theme?.closeButton ?? 'orange--extra'} />
          </CloseButton>
          {child}
        </Container>
      </div>
    )
  }

  const popupData: DataProps[] = dataJson
  const cardData = popupData.find((item: DataProps) => item.pageName === pageName) || popupData[2]
  const ctaLink = process.env.ENVIRONMENT === 'production' ? cardData.CTALinkPRD : cardData.CTALinkUAT

  return (
    <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
      <Container className='container' pj={pj} themeBackgroundColor={theme?.backgroundColor ?? "#fff"}>
        <CloseButton className='border-0 bg-transparent pt-4' onClick={handleClick}>
          <Close width='30' height='30' color={theme?.closeButton ?? 'orange--extra'} />
        </CloseButton>
        <DefaultPopUp>
          <div className='row'>
            <div className='col-12 col-md-4 d-flex justify-content-center justify-content-md-start align-items-center my-4 my-md-0'>
              <Img fluid={data[cardData.image].fluid} alt={cardData.altImage} />
            </div>
            <div className='col-12 col-md-8 pl-xl-4 align-items-center justify-content-center flex-column'>
              <h2 className={`fs-32 lh-40 fw-600 ${pj ? 'text-orange--extra' : 'text-grayscale--500' } mb-3`} dangerouslySetInnerHTML={{ __html: cardData.title }} />
              <p className={`fs-24 lh-30 ${pj ? 'text-white' : 'text-grayscale--400'} mb-0`} dangerouslySetInnerHTML={{ __html: cardData.description }} />
            </div>
            <div className='col-12'>
              <a
                href={ctaLink}
                className='btn btn--lg btn-orange--extra mw-100 fw-700 rounded-2 mt-5 text-none'
                title={cardData.CTAText}
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_0',
                    element_action: 'click button',
                    element_name: cardData.CTAText,
                    redirect_url: ctaLink,
                  })
                }}
              >
                {cardData.CTAText}
              </a>
            </div>
          </div>
        </DefaultPopUp>
      </Container>
    </div>
  )
}

export default Popup
