/* eslint-disable @typescript-eslint/typedef */
import { H1Citrina } from "src/components/Text/Headings"
import { breakpoints } from "src/styles/breakpoints"
import styled from "styled-components"

export const H1PlanilhaDeViagem = styled(H1Citrina)`
  font-weight: 700;
  @media (min-width: ${breakpoints.md}) {
    font-size: 40px;
    line-height: 44px;
    font-weight: 700;
  }

  @media (min-width: ${breakpoints.lg}) {
    font-size: 48px;
    line-height: 52px;
  }

  @media (min-width: ${breakpoints.xxl}) {
    font-size: 60px;
    line-height: 80px;
  }

  @media (min-width: ${breakpoints.xxll}) {
    font-size: 76px;
    line-height: 80px;
  }
`
