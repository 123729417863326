import { useEffect, useState } from "react"
import { getParameterByName } from "src/shared/helpers"
import { UtmParam } from "./types"

export const useUtmSourceFromUrl = () => {
  const [ utmSource, setUtmSource ] = useState<UtmParam>(null)
  useEffect(() => {
    setUtmSource(getParameterByName('utm_source', ''))
  }, [ ])

  return { utmSource }
}
