/* eslint-disable @typescript-eslint/typedef */
import { H2Citrina } from "src/components/Text/Headings"
import { breakpoints } from "src/styles/breakpoints"
import styled from "styled-components"

export const H2PlanilhaDeViagem = styled(H2Citrina)`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;

  @media (min-width: ${breakpoints.lg}) {
    font-size: 40px;
    line-height: 44px;
  }

  @media (min-width: ${breakpoints.xxl}) {
    font-size: 48px;
    line-height: 52px;
  }

  @media (min-width: ${breakpoints.xxll}) {
    font-size: 56px;
    line-height: 61px;
  }
`
