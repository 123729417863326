import { device } from "src/styles/breakpoints"
import { grayscale } from "src/styles/colors"
import { spacing16, spacing24, spacing32, spacing8 } from "src/styles/spacings"
import styled from "styled-components"

export const TextContent = styled.div`
  margin-bottom: ${spacing24};

  .title, .body-text {
    color: ${grayscale[500]}
  }

  .title {
    margin-bottom: ${spacing16};
    text-align: center;
  }

  .body-text {
    margin-bottom: ${spacing8};
  }

  @media ${device.tablet} {
    .title {
      text-align: left;
    }

    .body-text {
      margin-bottom: ${spacing16};
    }
  }

  @media ${device.desktopXXL} {
    .title {
      margin-bottom: ${spacing32};
      width: 120%;
    }
  }
`

export const ImageContent = styled.div`
  margin-bottom: ${spacing24};
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
  }
`

export const CtaBox = styled.div`
  width: 100%;
  .button-link {
    text-decoration: none;
    height: fit-content;
  }

  @media ${device.tablet} {
    & {
      margin-top: ${spacing32};
    }
  }

  @media ${device.desktopXXL} {
    & {
      margin-top: calc(${spacing32} + ${spacing8});
    }
  }
`
