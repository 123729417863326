import React, { useEffect } from 'react'

import Layout from 'src/layouts/BaseLayout'
import pageContext from './pageContext.json'

import {
  S1BaixeGratis,
  S2SimplifiqueSuaViagem,
  S3APlanilhaPerfeita,
  S4MaterialPraVoce,
  S5ComeceAPlanejar,
  S6AfinalQuantoCusta,
} from './_sections/_index'
import { WithCommonSectionPropsT } from 'src/hoc/withCommonSectionProps'
import { Theme, initTheme } from '@interco/inter-ui'

export type PlanilhaViagensSectionsProps = WithCommonSectionPropsT & {id: string; dataLayerSection: string}

const PlanilhaDeViagem = () => {
  useEffect(() => (
    initTheme(Theme.PF)
  ), [])

  return (
    <Layout pageContext={pageContext}>
      <S1BaixeGratis id='section-baixe-gratis' dataLayerSection='dobra_01' />
      <S2SimplifiqueSuaViagem id='section-simplifique-sua-viagem' dataLayerSection='dobra_02' />
      <S3APlanilhaPerfeita id='section-a-planilha-perfeita' dataLayerSection='dobra_03' />
      <S4MaterialPraVoce id='section-material-pra-voce' dataLayerSection='dobra_04' />
      <S5ComeceAPlanejar id='section-comece-a-planejar' dataLayerSection='dobra_05' />
      <S6AfinalQuantoCusta id='section-afinal-quanto-custa' dataLayerSection='dobra_06' />
    </Layout>
  )
}

export default PlanilhaDeViagem
