import React from 'react'
import * as S from './styles'
import { PlanilhaViagensSectionsProps } from '../..'
import withCommonSectionProps from 'src/hoc/withCommonSectionProps'
import SectionComponent from 'src/components/SectionComponent'
import { grayscale } from 'src/styles/colors'
import { H2PlanilhaDeViagem } from '../../_components/_H2PlanilhaDeViagens'
import { InterP } from 'src/components/Text/BodyText'
import Picture from 'src/components/Picture'
import { Button } from '@interco/inter-ui/components/Button'
import { WIDTH_MD } from 'src/styles/breakpoints'
import ScrollTo from 'src/components/ScrollTo'

type ComeceAPlanejarProps = PlanilhaViagensSectionsProps

const TEXT_CONTENT = {
  heading: 'Comece a planejar sua viagem agora mesmo',
  body: 'Com a <strong>planilha de gastos de viagem em Excel</strong>, você controla suas despesas de forma eficiente e se organiza durante toda a jornada.',
  cta: 'Baixar grátis',
}

const ComeceAPlanejar = ({ id, dataLayerSection, width }: ComeceAPlanejarProps) => {
  return (
    <SectionComponent
      id={id}
      bgColor={grayscale[100]}
      minHeight={{ sm: '620px', md: '416px', lg: '504px', xl: '648px', xxl: '764px' }}
    >
      <S.TextContent className='col-12 col-md-6 order-md-2 col-xl-5 offset-xl-2'>
        <H2PlanilhaDeViagem className='title'>{TEXT_CONTENT.heading}</H2PlanilhaDeViagem>
        <InterP className='body-text' dangerouslySetInnerHTML={{ __html: TEXT_CONTENT.body }} />
        {width >= WIDTH_MD && (
          <S.CtaBox>
            <ScrollTo
              to='#section-baixe-gratis'
              section={dataLayerSection}
              sectionName={TEXT_CONTENT.heading}
              elementName={TEXT_CONTENT.cta}
              className='button-link'
            >
              <Button fullWidth>
                {TEXT_CONTENT.cta}
              </Button>
            </ScrollTo>
          </S.CtaBox>
        )}
      </S.TextContent>
      <S.ImageContent className='col-12 col-md-6 order-md-1 col-lg-5 col-xl-5'>
        <Picture
          src='https://central-imagens.bancointer.com.br/images-without-small-versions/planilha-dobra5/image.png'
          minHeight={{ xl: '456px', lg: '376px', md: '336px', sm: '312px' }}
          width='100%'
          alt='Mulher sentada em poltrona usando o celular com mala de viagem ao lado.'
        />
      </S.ImageContent>
      {width < WIDTH_MD && (
        <S.CtaBox>
          <ScrollTo
            to='#section-baixe-gratis'
            section={dataLayerSection}
            sectionName={TEXT_CONTENT.heading}
            elementName={TEXT_CONTENT.cta}
            className='button-link'
          >
            <Button fullWidth>
              {TEXT_CONTENT.cta}
            </Button>
          </ScrollTo>
        </S.CtaBox>
      )}

    </SectionComponent>
  )
}

export default withCommonSectionProps(ComeceAPlanejar)
