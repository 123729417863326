import axios from 'axios'
import { useMemo } from 'react'
import * as URLS from 'src/config/api/Urls'
import { UtmParam } from 'src/hooks/useUtms/types'

export type PlanilhaDeViagemForm = {
  campanha: string;
  nome: string;
  CpfCnpj: string;
  email: string;
  aceite: boolean;
  conteudo01: UtmParam;
  conteudo02: UtmParam;
  conteudo03: UtmParam;
}

type PlanilhaDeViagemAPIResponse = {
  success: boolean;
  data: {
    failed: [];
    successful: {
      id: string;
      mD5OfMessageAttributes: null;
      mD5OfMessageBody: string;
      mD5OfMessageSystemAttributes: null;
      messageId: string;
      sequenceNumber: null;
    }[];
    responseMetadata: {
      requestId: string;
      metadata: {};
      checksumAlgorithm: number;
      checksumValidationStatus: number;
    };
    contentLength: number;
    httpStatusCode: number;
  }[];
  errors: [];
  requestId: string;
}

export class PlanilhaDeViagemService {
  private url: string = `${URLS.CONTACT_FORM_POST_V5}/lp-mktcloud-inter`

  async submitForm (formData: PlanilhaDeViagemForm): Promise<PlanilhaDeViagemAPIResponse | undefined> {
    const response = await axios.post(this.url, [ formData ], {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return response.data
  }
}

export const usePlanilhaDeViagemService = () => {
  const service = useMemo(() => new PlanilhaDeViagemService(), [])
  return service
}
