import React from "react"
import withCommonSectionProps from "src/hoc/withCommonSectionProps"
import { PlanilhaViagensSectionsProps } from "../.."
import SectionComponent from "src/components/SectionComponent"
import { primary } from "src/styles/newColors"
import { H2PlanilhaDeViagem } from "../../_components/_H2PlanilhaDeViagens"
import * as S from "./styles"
import DefaultCarousel from "src/components/UI/Carousels/DefaultCarousel"
import { orange } from "src/styles/colors"
import { Button } from '@interco/inter-ui/components/Button'
import { WIDTH_MD } from "src/styles/breakpoints"
import IconsSwitch from "src/components/IconsSwitch/_index"
import ScrollTo from "src/components/ScrollTo"

const TEXT_CONTENT = {
  heading: 'Simplifique sua viagem com um planejamento prático: tudo em um só lugar',
  cardsItems: [
    { title: 'Planilha de gastos', body: 'Calcule o custo de viagem pra te ajudar a se organizar financeiramente.', icon: 'finance/ic_wallet' },
    { title: 'Checklist', body: 'Pra você não esquecer de nenhum documento, tarefa ou item importante.', icon: 'action-navigation/ic_checklist' },
    { title: 'Roteiro de viagem', body: 'Monte sua programação de traslados, hotéis, passeios e aproveite ao máximo.', icon: 'travel-mobility/ic_luggage' },
  ],
  cta: 'Baixar planilha',
}

const SimplifiqueSuaViagem = ({ id, width, dataLayerSection }: PlanilhaViagensSectionsProps) => {
  return (
    <SectionComponent
      id={id}
      bgColor={primary[200]}
      SectionStyles='px-0'
      minHeight={{ sm: '547px', md: '636px', lg: '663px', xl: '813px', xxl: '853px' }}
    >
      <S.TextContent className='col-12'>
        <H2PlanilhaDeViagem className='title'>{TEXT_CONTENT.heading}</H2PlanilhaDeViagem>
      </S.TextContent>
      {width < WIDTH_MD && (
        <S.CarouselBox className='col-12'>
          <DefaultCarousel
            notShowArrows
            sm={{ items: 1, partialVisibilityGutter: 30 }}
            customDotColor={orange.extra}
          >
            {TEXT_CONTENT.cardsItems.map((item) => (
              <li className='carousel-item' key={item.title.toLowerCase()}>
                <h3 className='carousel-item__title'>{item.title}</h3>
                <div className='carousel-item__picture'>
                  <IconsSwitch size='LG' customPath='' icon={item.icon} lib='interco' color={orange.extra} />
                </div>
                <p className='carousel-item__body'>
                  {item.body}
                </p>
              </li>
          ))}
          </DefaultCarousel>
        </S.CarouselBox>
      )}
      {width >= WIDTH_MD && (
        <div className='col-12'>
          <S.CardsBox>
            {TEXT_CONTENT.cardsItems.map((item) => (
              <li className='card' key={item.title.toLowerCase().trim()}>
                <h3 className='card__title'>{item.title}</h3>
                <div className='card__picture'>
                  <IconsSwitch size='LG' customPath='' icon={item.icon} lib='interco' color={orange.extra} />
                </div>
                <p className='card__body'>{item.body}</p>
              </li>
          ))}
          </S.CardsBox>
        </div>
      )}
      <S.CtaBox className='col-12'>
        <ScrollTo
          section={dataLayerSection}
          sectionName={TEXT_CONTENT.heading}
          elementName={TEXT_CONTENT.cta}
          to='#section-baixe-gratis'
          className='button-link'
          title={TEXT_CONTENT.cta}
        >
          <Button
            fullWidth
            type='button'
          >
            {TEXT_CONTENT.cta}
          </Button>
        </ScrollTo>
      </S.CtaBox>
    </SectionComponent>
  )
}

export default withCommonSectionProps(SimplifiqueSuaViagem)
