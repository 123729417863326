import { device } from "src/styles/breakpoints"
import { grayscale } from "src/styles/colors"
import { primary } from "src/styles/newColors"
import { spacing16, spacing24, spacing32, spacing8 } from "src/styles/spacings"
import styled from "styled-components"

export const TextContent = styled.div`
  text-align: center;

  .title {
    margin-bottom: ${spacing16};
  }

  @media ${device.tablet} {
    margin-bottom: 56px;
  }

  @media ${device.desktopLG} {
    margin-bottom: 48px;
  }

  @media ${device.desktopXL} {
    margin-bottom: 56px;
  }
`
// only used on mobile view
export const CarouselBox = styled.div`

  & .react-multi-carousel-track {
    padding: 0;
    margin-bottom: ${spacing32};
  }

  & .react-multi-carousel-dot-list {
    bottom: ${spacing16};
  }

  & .carousel-item {
    background-color: #fff;
    padding: ${spacing32} ${spacing24};
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-between;
    align-items: center;
    border-radius: ${spacing32} ${spacing24};
    margin-right: ${spacing8};
    margin-bottom: ${spacing16};
    .carousel-item__title {
      font-size: 18px;
      font-weight: 600;
      line-height: 22px;
      text-align: center;
    }

    .carousel-item__picture {
      width: 93px;
      height: 93px;
      padding: 16px;
      border-radius: 50%;
      margin-bottom: ${spacing32};
      background-color: ${primary[100]};
      svg {
        width: 100%;
        height: 100%;
      }
    }

    .carousel-item__body {
      font-size: 14px;
      line-height: 16px;
      color: ${grayscale[500]}
    }
  }

`

export const CardsBox = styled.ul`
  @media ${device.tablet} {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(3, minmax(210px, 1fr));
    column-gap: 28px;
    width: 100%;
    margin-bottom: 56px;

    & .card {
      margin: 0 !important;
      background-color: #fff;
      padding: ${spacing32} ${spacing24};
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: space-between;
      align-items: center;
      border-radius: ${spacing32} ${spacing24};
      .card__title {
        font-size: 18px;
        font-weight: 600;
        line-height: 22px;
        text-align: center;
      }
  
      .card__picture {
        width: 93px;
        height: 93px;
        padding: 16px;
        border-radius: 50%;
        margin-bottom: ${spacing32};
        background-color: ${primary[100]};
        svg {
          width: 100%;
          height: 100%;
        }
      }
  
      .card__body {
        font-size: 14px;
        line-height: 16px;
        color: ${grayscale[500]}
      }
    }

  }

  @media ${device.desktopLG} {
    & {
      column-gap: 56px;
      margin-bottom: 48px;
    }
    & .card {
      .card__title {
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
      }

      .card__body {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  @media ${device.desktopXL} {
    & {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 56px;
      grid-template-columns: repeat(3, minmax(210px, 272px));
      width: fit-content;
    }
  }

  @media ${device.desktopXXLL} {
    & {
      grid-template-columns: repeat(3, minmax(210px, 306px));
    }

    & .card {
      .card__title {
        font-size: 28px;
        line-height: 33px;
        margin-bottom: ${spacing32};
      }

      .card__body {
        font-size: 18px;
        line-height: 22px;
      }
    }
  }
`

export const CtaBox = styled.div`
  .button-link {
    display: block;
    height: fit-content;
    text-decoration: none;
  }

  @media ${device.tablet} {
    & {
      max-width: 216px;
      margin: 0 auto;
    }
  }

  @media ${device.desktopLG} {
    & {
      max-width: 296px;
    }
  }

  @media ${device.desktopXL} {
    & {
      max-width: 272px;
    }
  }

  @media ${device.desktopXL} {
    & {
      max-width: 306px;
    }
  }
`
