import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

type DefaultPopUpProps = {
  pj?: boolean;
  themeBackgroundColor: string;
}

export const Container = styled.div`
  width: 98%;
  min-height: 98vh;
  display: flex;
  align-items: center;
  border-radius: 8px;
  background:  ${(props: DefaultPopUpProps) => props.themeBackgroundColor ? props.themeBackgroundColor : '#ffffff'};

  @media ${device.mobile} {
    width: fit-content;
    display: block;
    position: relative;
    padding: 48px 70px;
    min-height: 376px;
    max-width: 80%;
  }

  @media ${device.desktopXL} {
    max-width: 60%;
  }

  @media ${device.desktopXXXL} {
    max-width: 45%;
  }

  a {
    width: 100%;
    min-height: 48px;
    font-size: 16px;
  }
`

export const CloseButton = styled.button`
  position: absolute;
  right: 20px;
  top: 0;
  z-index: 10;
  outline: none !important;
`

export const DefaultPopUp = styled.div`
  max-width: 644px !important;
`
