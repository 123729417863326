import React from 'react'
import * as S from './styles'
import { InterP } from 'src/components/Text/BodyText'
import { H2PlanilhaDeViagem } from '../../_components/_H2PlanilhaDeViagens'
import Picture from 'src/components/Picture'
import { Button } from '@interco/inter-ui/components/Button'
import { PlanilhaViagensSectionsProps } from '../..'
import withCommonSectionProps from 'src/hoc/withCommonSectionProps'
import SectionComponent from 'src/components/SectionComponent'
import { WIDTH_MD } from 'src/styles/breakpoints'
import ScrollTo from 'src/components/ScrollTo'

type AfinalQuantoCustaProps = PlanilhaViagensSectionsProps

const TEXT_CONTENT = {
  heading: 'Afinal, quanto custa viajar?',
  subheading: 'Você vai descobrir com a ajuda deste excel',
  body: 'É só registrar todas as despesas já realizadas e as que ocorrerão durante o passeio pra controlar seus gastos. É possível calcular o custo total da viagem, incluindo:',
  imageComplementText: 'É possível calcular o custo total da viagem, incluindo:',
  alt: 'É possível calcular o custo total da viagem, incluindo: Passagens, Presentes, Transporte, Hospedagens, Alimentação, Passeios, e mais',
  cta: 'Baixar agora',
}

const AfinalQuantoCusta = ({ id, width, dataLayerSection }: AfinalQuantoCustaProps) => (
  <SectionComponent
    id={id}
    minHeight={{ sm: '481px', md: '378px', lg: '392px', xl: '493px', xxl: '522px' }}
  >
    <S.TextContent className='col-12 col-md-6'>
      <H2PlanilhaDeViagem className='title'>{TEXT_CONTENT.heading}</H2PlanilhaDeViagem>
      <p className='subheading'>{TEXT_CONTENT.subheading}</p>
      <InterP className='body-text'>{TEXT_CONTENT.body}</InterP>
      {width >= WIDTH_MD && (
        <S.CtaBox>
          <ScrollTo
            to='#section-baixe-gratis'
            section={dataLayerSection}
            sectionName={TEXT_CONTENT.heading}
            elementName={TEXT_CONTENT.cta}
            className='button-link'
          >
            <Button
              fullWidth
              type='button'
            >
              {TEXT_CONTENT.cta}
            </Button>
          </ScrollTo>
        </S.CtaBox>
      )}
    </S.TextContent>
    <S.ImageContent className='col-12 col-md-6'>
      {width >= WIDTH_MD && <InterP className='img-complement-text' aria-hidden='true'>{TEXT_CONTENT.imageComplementText}</InterP>}
      <Picture
        src='https://central-imagens.bancointer.com.br/images-without-small-versions/planilha-dobra6/image.png'
        minHeight={{ sm: '151px', md: '147px', lg: '221px', xl: '252px' }}
        width='100%'
        alt={TEXT_CONTENT.alt}
      />
    </S.ImageContent>
    {width < WIDTH_MD && (
      <S.CtaBox>
        <ScrollTo
          to='#section-baixe-gratis'
          section={dataLayerSection}
          sectionName={TEXT_CONTENT.heading}
          elementName={TEXT_CONTENT.cta}
          className='button-link'
        >
          <Button
            fullWidth
            type='button'
          >
            {TEXT_CONTENT.cta}
          </Button>
        </ScrollTo>
      </S.CtaBox>
    )}
  </SectionComponent>
)

export default withCommonSectionProps(AfinalQuantoCusta)
