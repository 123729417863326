import React, { useEffect, useState } from 'react'

import SectionComponent from 'src/components/SectionComponent'
import { InterP } from 'src/components/Text/BodyText'
import * as S from './styles'
import { WIDTH_MD } from 'src/styles/breakpoints'
import withCommonSectionProps from 'src/hoc/withCommonSectionProps'
import { PlanilhaViagensSectionsProps } from '../..'
import { H1PlanilhaDeViagem } from '../../_components/_H1PlanilhaDeViagens'
import { COMMON_MASKS, Input } from '@interco/inter-ui/components/Input'
import { useForm } from 'react-hook-form'
import * as Validations from 'inter-frontend-lib-util-form/lib/validations'
import { Checkbox } from '@interco/inter-ui/components/Checkbox'
import { Button } from '@interco/inter-ui/components/Button'
import Picture from 'src/components/Picture'
import classnames from 'classnames'
import withBaixeGratisPopup, { BaixeGratisPopupContextValue, useBaixeGratisPopup } from '../../_components/BaixeGratisPopup/_BaixeGratisPopupContext'
import { useUtmMediumFromUrl } from 'src/hooks/useUtms/useUtmMediumFromUrl'
import { useUtmSourceFromUrl } from 'src/hooks/useUtms/useUtmSourceFromUrl'
import { useUtmCampaignFromUrl } from 'src/hooks/useUtms/useUtmCampaignFromUrl'
import { usePlanilhaDeViagemService } from 'src/services/planilha-de-viagem/PlanilhaDeViagemService'

const TEXT_CONTENT = {
  heading: 'Planilha de viagem',
  body: 'Garanta o <strong>excel pronto pra calcular custo de viagem</strong>, se planejar com um checklist completo + modelo de <strong>roteiro</strong>.',
  formHeading: 'Baixe grátis seu planejamento de viagem',
  cta: 'Baixar planilha',
}

const DOWNLOAD_URL = 'https://marketing.bancointer.com.br/arquivos/planilhas/Planilha-de-Viagem.xlsx'

type BaixeGratisForm = {
  nome: string;
  email: string;
  cpf: string;
}

const BaixeGratis = ({ width, sendDataLayerEvent, id, dataLayerSection }: PlanilhaViagensSectionsProps) => {
  // form related states
  const { register, errors, handleSubmit, reset, formState }:
    Pick<ReturnType<typeof useForm>, 'register' | 'errors' | 'handleSubmit' | 'setValue' | 'reset'| 'formState'> = useForm({
    mode: 'onBlur',
  })
  const [ isAcceptTermChecked, setIsAcceptTermChecked ] = useState<boolean>(false)
  const { isValid: isFormValid }: {isValid: boolean} = formState
  // controls whether form submit was successful or not
  const [ hasErrorOnSubmit, setHasErrorOnSubmit ] = useState<boolean>(false)
  const [ isSendingData, setIsSendingData ] = useState<boolean>(false)

  // http dependency
  const httpService = usePlanilhaDeViagemService()
  // popup context
  const { hasCtaBeenClicked, openPopup, closePopup, setPopupHasError }: BaixeGratisPopupContextValue = useBaixeGratisPopup()

  // form data
  const { utmSource }: ReturnType<typeof useUtmSourceFromUrl> = useUtmSourceFromUrl()
  const { utmMedium }: ReturnType<typeof useUtmMediumFromUrl> = useUtmMediumFromUrl()
  const { utmCampaign }: ReturnType<typeof useUtmCampaignFromUrl> = useUtmCampaignFromUrl()

  const hiddenAnchorRef = React.useRef<HTMLAnchorElement>(null)

  const resetForm = () => {
    setIsAcceptTermChecked(false)
    reset()
  }

  const sendForm = async (data: BaixeGratisForm) => {
    setIsSendingData(true)
    setHasErrorOnSubmit(false)

    const utmData = {
      conteudo01: utmSource,
      conteudo02: utmMedium,
      conteudo03: utmCampaign,
    }

    const formData = {
      campanha: 'Planilha de Viagem',
      nome: data.nome,
      CpfCnpj: data.cpf.replace(/\D/g, ''),
      email: data.email,
      aceite: isAcceptTermChecked,
      ...utmData,
    }

    const onSuccessfulSubmit = () => {
      setPopupHasError(false)
      sendDataLayerEvent({
        section: TEXT_CONTENT.heading,
        section_name: dataLayerSection,
        element_name: TEXT_CONTENT.cta,
        element_action: 'submit',
        step: 'success',
      })
    }

    const onFailedSubmit = (e: Error) => {
      console.error('BaixeGratis section: error sending data to server:', e)
      setPopupHasError(true)
      setHasErrorOnSubmit(true)
    }

    try {
      const response = await httpService.submitForm(formData)
      if (response?.success) onSuccessfulSubmit()
    } catch (e: any) {
      onFailedSubmit(e)
    } finally {
      openPopup()
      setIsSendingData(false)
      resetForm()
    }
  }

  useEffect(() => {
    const meetsConditionsToDownload = hasCtaBeenClicked && hiddenAnchorRef.current && !hasErrorOnSubmit
    if (meetsConditionsToDownload) {
      hiddenAnchorRef.current.click()
    }
    closePopup()
  }, [ hasCtaBeenClicked ])

  return (
    <SectionComponent
      id={id}
      minHeight={{ sm: '933px', md: '567px', lg: '616px', xl: '763px', xxl: '835px' }}
    >
      <a
        style={{ display: 'none' }}
        href={DOWNLOAD_URL}
        ref={hiddenAnchorRef}
      />
      {width < WIDTH_MD && (
        <div className='col-12'>
          <S.TextContent>
            <H1PlanilhaDeViagem className='title'>{TEXT_CONTENT.heading}</H1PlanilhaDeViagem>
            <InterP className='body' dangerouslySetInnerHTML={{ __html: TEXT_CONTENT.body }} />
          </S.TextContent>
        </div>
      )}
      <div className='col-12 col-md-6 order-md-2 col-xl-5 offset-xl-1 offset-xxl-2'>
        <S.FormBox name='baixe-gratis-seu-planejamento' onSubmit={handleSubmit(sendForm)}>
          <p className='form__title'>{TEXT_CONTENT.formHeading}</p>
          <Input
            className={classnames('form__input', { 'form__input--error': errors.nome })}
            id='nome'
            label='Nome'
            name='nome'
            placeholder='Digite seu nome completo'
            type='text'
            infoText={errors.nome?.message}
            ref={register({
              required: 'Nome obrigatório',
              maxLength: 100,
            })}
            error={errors.nome}
          />
          <Input
            className={classnames('form__input', { 'form__input--error': errors.email })}
            id='email'
            label='E-mail'
            name='email'
            type='email'
            placeholder='Digite seu e-mail'
            infoText={errors.email?.message}
            ref={register({
              required: 'E-mail obrigatório',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Insira um e-mail válido',
              },
            })}
            error={errors.email}
          />
          <Input
            className={classnames('form__input', { 'form__input--error': errors.cpf })}
            id='cpf'
            label='CPF'
            name='cpf'
            type='tel'
            mask={COMMON_MASKS.CPF}
            infoText={errors.cpf?.message}
            placeholder='Digite seu CPF'
            ref={register({
              required: 'CPF obrigatório',
              validate: {
                isCpf: (value: string) => Validations.cpf(value) || 'CPF Inválido',
              },
            })}
            error={errors.cpf}
          />
          <div className='form__accept-checkbox__label' onClick={() => setIsAcceptTermChecked((prev: boolean) => !prev)}>
            <Checkbox
              checked={isAcceptTermChecked}
              onClick={() => setIsAcceptTermChecked((prev: boolean) => !prev)}
            />
            <span className='form__accept-checkbox__text'>Aceito receber as comunicações e concordo com a
              <a
                title='Acessar Política de Privacidade do Inter'
                href='https://inter.co/politica-de-privacidade/privacidade/'
                target='_blank'
                rel='noreferrer'
              >
                Política de Privacidade
              </a>.
            </span>
          </div>
          <Button
            type='submit'
            fullWidth
            disabled={!isAcceptTermChecked || isSendingData || !isFormValid}
          >
            { isSendingData ? 'Enviando Dados...' : TEXT_CONTENT.cta}
          </Button>
        </S.FormBox>
      </div>
      <div className='col-12 col-md-6 order-md-1 col-xxl-5'>
        {width >= WIDTH_MD && (
          <S.TextContent>
            <H1PlanilhaDeViagem className='title'>{TEXT_CONTENT.heading}</H1PlanilhaDeViagem>
            <InterP className='body' dangerouslySetInnerHTML={{ __html: TEXT_CONTENT.body }} />
          </S.TextContent>
        )}
        <S.ImageContent>
          <Picture
            src='https://central-imagens.bancointer.com.br/images-without-small-versions/planilha-hero/image.png'
            width='100%'
            imgClassName='hero-img'
            minHeight={{ sm: '239px', md: '256px', lg: '343px', xl: '415px' }}
          />
        </S.ImageContent>
      </div>
    </SectionComponent>

  )
}

export default withCommonSectionProps(withBaixeGratisPopup(BaixeGratis))
