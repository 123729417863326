import { useEffect, useState } from "react"
import { getParameterByName } from "src/shared/helpers"

export const useUtmMediumFromUrl = () => {
  const [ utmMedium, setUtmMedium ] = useState<string | string[] | null | undefined>(null)
  useEffect(() => {
    setUtmMedium(getParameterByName('utm_medium', ''))
  }, [ ])

  return { utmMedium }
}
