import { device } from "src/styles/breakpoints"
import { grayscale, orange, red } from "src/styles/colors"
import { spacing16, spacing24, spacing8 } from "src/styles/spacings"
import styled from "styled-components"

export const TextContent = styled.div`
  text-align: center;
  .title {
    margin-bottom: ${spacing16};
    color: ${orange.extra}
  }

  .body {
    margin-bottom: ${spacing24};
  }

  @media ${device.tablet} {
    & {
      text-align: left;
    }
    .body {
      margin-bottom: ${spacing16};
    }
  }

  @media ${device.desktopXXLL} {
    .title, .body {
      margin-bottom: ${spacing24};
    }
  }
`

export const FormBox = styled.form`
  background-color: ${grayscale[100]};
  padding: ${spacing24};
  border-radius: ${spacing16};
  box-shadow: 0px 0px 25px 0px #00000040;
  height: fit-content;
  width: 100%;
  margin-bottom: ${spacing16};

  .form {
    display: grid;
  }

  .form__title {
    font-family: 'Citrina', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    margin-bottom: ${spacing24};
    color: ${grayscale[500]}
  }

  .form__input {
    width: 100%;
    input {
      background-color: #fff;
      border-radius: 8px;
    }
    &.form__input--error {
      input {
        background-color: ${red.error} !important;
      }
    }
  }

  .form__accept-checkbox__label {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: ${spacing8};
    align-items: center;
    margin-bottom: ${spacing16};
  }

  .form__accept-checkbox__text {
    display: inline-block;
    font-size: 10px;
    color: ${grayscale[500]};
    a {
      font-weight: 600;
      color: ${orange.extra};
      padding-left: 4px;
    }
  }

  @media ${device.desktopXXL} {
    .form__title {
      font-size: 28px;
      line-height: 33px;
    }
  }

  @media ${device.desktopXXLL} {
    .form__title {
      font-size: 40px;
      line-height: 44px;
    }
  }
`

export const ImageContent = styled.div`

  @media ${device.desktopXXLL} {
    .hero-img {
      max-height: 463px;
    }
  }
`
