import React from 'react'
import SectionComponent from "src/components/SectionComponent"
import withCommonSectionProps from "src/hoc/withCommonSectionProps"
import { PlanilhaViagensSectionsProps } from "../.."
import { H2PlanilhaDeViagem } from "../../_components/_H2PlanilhaDeViagens"
import * as S from './styles'
import { InterP } from "src/components/Text/BodyText"
import Picture from 'src/components/Picture'
import { grayscale } from 'src/styles/colors'
import { WIDTH_MD } from 'src/styles/breakpoints'
import { Button } from '@interco/inter-ui/components/Button'
import ScrollTo from 'src/components/ScrollTo'

type APlanilhaPerfeitaProps = PlanilhaViagensSectionsProps

const TEXT_CONTENT = {
  heading: 'A planilha perfeita pra você planejar sua viagem internacional',
  body1: '<strong>Quanto custa viajar para o exterior?</strong> Quais documentos preciso providenciar? Como organizar meus voos, traslados, hotéis e passeios?',
  body2: 'Com a planilha de viagem do Inter, você organiza toda sua viagem internacional em um só lugar e evita dores de cabeça.',
  cta: 'Baixar grátis',
}

const APlanilhaPerfeita = ({ id, width, dataLayerSection }: APlanilhaPerfeitaProps) => (
  <SectionComponent
    id={id}
    bgColor={grayscale[100]}
    minHeight={{ sm: '599px', md: '396px', lg: '489px', xl: '625px', xxl: '700px' }}
  >
    <S.TextContent className='col-12 col-md-6 col-xl-5'>
      <H2PlanilhaDeViagem className='title'>{TEXT_CONTENT.heading}</H2PlanilhaDeViagem>
      <InterP className='body-text' dangerouslySetInnerHTML={{ __html: TEXT_CONTENT.body1 }} />
      <InterP>{TEXT_CONTENT.body2}</InterP>
      {width >= WIDTH_MD && (
        <S.CtaBox>
          <ScrollTo
            to='#section-baixe-gratis'
            section={dataLayerSection}
            sectionName={TEXT_CONTENT.heading}
            elementName={TEXT_CONTENT.cta}
            className='button-link'
          >
            <Button
              fullWidth
              type='button'
            >
              {TEXT_CONTENT.cta}
            </Button>
          </ScrollTo>
        </S.CtaBox>
      )}
    </S.TextContent>

    <S.ImageContent className='col-12 col-md-6 col-lg-5 offset-lg-1 col-xl-6'>
      <Picture
        src='https://central-imagens.bancointer.com.br/images-without-small-versions/planilha-dobra3/image.png'
        minHeight={{ xxl: '508px', xl: '417px', lg: '300px', md: '221px', sm: '221px' }}
        minWidth={{ xxl: '716px', xl: '588px', lg: '420px', md: '309px', sm: '309px' }}
        alt='Print da planilha de viagem com gráfico de despesas de viagem ao lado da relação de gastos.'
      />
    </S.ImageContent>
    {width < WIDTH_MD && (
      <S.CtaBox>
        <ScrollTo
          to='#section-baixe-gratis'
          section={dataLayerSection}
          sectionName={TEXT_CONTENT.heading}
          elementName={TEXT_CONTENT.cta}
          className='button-link'
        >
          <Button
            fullWidth
            type='button'
          >
            {TEXT_CONTENT.cta}
          </Button>
        </ScrollTo>
      </S.CtaBox>
    )}
  </SectionComponent>
)

export default withCommonSectionProps(APlanilhaPerfeita)
