import { useEffect, useState } from "react"
import { getParameterByName } from "src/shared/helpers"

export const useUtmCampaignFromUrl = () => {
  const [ utmCampaign, setUtmCampaign ] = useState<string | string[] | null | undefined>(null)
  useEffect(() => {
    setUtmCampaign(getParameterByName('utm_campaign', ''))
  }, [ ])

  return { utmCampaign }
}
